import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>Hi, I’m Joe, the founder of BellMe</h1>
                                <p>When the company went live on Sept 4, 2001, a week before 9-11, I was a one vehicle,
                                    Black Car, independent owner operator with 13 years’ driving experience. Prior to
                                    that, I worked for a large fleet operator for over a year as a contractor with my
                                    1997 Town Car. At that time, that fleet operator skirted the independent contractor
                                    laws and forbade their operators to work for other competitors or for themselves.
                                    Times have changed for the better! I now have over 30 years of fleet company
                                    experience and have personally driven over 1,000,000 customer miles. Driving was
                                    never a job, it was a pleasure. If you share my attitude and work ethic, you’ll
                                    never have to go to work! (read 2001 Chicago Sun-Times article).</p>
                                <p>When I first started driving in Chicago over 30 years, I had to take a one-day
                                    chauffeurs’ class (see Servicing a Handicap Client) along with taxi drivers who had
                                    a three-day course. It is beyond me why Gig drivers with Lyft and Uber do not have
                                    any mandatory training. The consumer suffers. But most consumers only care about two
                                    things. Convenience and Cheap Prices. Most consumers will make sacrifices to save a
                                    few dollars and not consider their total satisfaction or safety. These are not the
                                    consumers we want as clients.</p>
                                <p>I have plenty of experience to share. I’m a good mentor and want to give back anyway
                                    I can. I know what drivers want; steady work, good customers, and a living wage. I
                                    also know what consumers expect and demand from a good driver; consistency,
                                    friendliness, knowledge with routes, drive times, city history and hot spots, being
                                    accommodative, and they want a convenient way to book a worry-free travel
                                    experience, and of course, never be overcharged. </p>
                                <p>We never Surge Price.</p>
                                <p>Personalization, Customer Service, and 100% Driver Support makes BellMe the
                                    best on every level! We will never support autonomous driving vehicles in our
                                    industry. Millions of jobs will be lost. We want to keep good citizens working and
                                    continue to have purpose in life.
                                    My first corporate account developed the in-flight telephone and was installing
                                    their improved digital version on USAir’s fleet in the early 1990’s. SKYLIMO, my
                                    second limo company, was going to be the first Global Network to advertise and allow
                                    air travelers to book a ride at 30,000 feet, at any airport before landing. Black
                                    and White Limousine was my first company. I owned two vehicles, a black 1997 Town
                                    Car sedan (I still own it) and white 1999, 6 pax Town Car stretch. I was a four-car
                                    operator when I was at my busiest. I eventually figured it’s much easier and more
                                    efficient to network rather than own a fleet of vehicles.
                                </p>
                                <p>This was my lightbulb moment. I knew first-hand how difficult it was for consumers to
                                    find quality ground transportation, especially in an unfamiliar city or airport. It
                                    wasn’t easy to find a good driver 10 or more years ago. Travelers either had their
                                    own network of drivers or it was making numerous phone calls. It was very time
                                    consuming. Trying to find a reasonable rate, and hope that the driver showed up
                                    on-time, was all a hassle and worrisome.
                                </p>
                                <p>The problem, “How do I economically and efficiently communicate with a global
                                    workforce of drivers in real-time?” Cell phones and two-way pagers where my only
                                    expensive and inefficient options. My Army training as a wireless telephone
                                    (cellular) repairman in 1972 and being familiar with using walkie-talkies (simplex
                                    communications – one person can only talk at a time) which connected my car to the
                                    fleet dispatcher and other drivers, was the only standard. That technology worked on
                                    a local basis, but not nationally or globally.
                                </p>

                                <p>So, while IFPC was installing their new in-flight phone system, with a video screen
                                    on the back of each headrest, I approached Motorola to partner in developing a
                                    mobile handheld communicator of my own. Flat panel computers, called PDAs (Personal
                                    Digital Assistants), where coming to market in 1994. The dawn of wireless
                                    communications was just beginning. This was the perfect platform to solve my
                                    dilemma, except, it did not incorporate the two most important technologies that I
                                    needed. It was missing two-way voice and data, duplex communications (two people
                                    talking simultaneously).
                                </p>

                                <p>Motorola thanked me with a pat on the back for a good idea but turned me down but
                                    suggested to contact Apple Computer, since they developed the Newton hand-held
                                    computer, which Motorola made the chips. Apple also turned me down because Apple was
                                    almost bankrupt, and they shut down the Newton division for lack of funding. Steve
                                    jobs was not the first to think of the iPhone concept. 12 years later, the first
                                    iPhone came to market. Travis Kalanick (Uber) and Logan Green and John Zimmer (Lyft)
                                    weren’t the first to think of a global network of drivers. It was 14 years after my
                                    Apple letter, in 2009, that Lyft and Uber started. Neither company would be in
                                    business today without the iPhone’s technology. (read Apple letter).
                                </p>

                                <p>In-Flight Phone couldn’t make their technology work and went bankrupt in 1996, but I
                                    kept the Global Network idea alive. When the Internet came along in the mid to late
                                    1990’s, I started BellMe in 2001. I saw that the Internet was just not a
                                    catalog of things and information, but a communication platform. The new company was
                                    modeled after BellMe by dispatching the closest vehicle, with the lowest
                                    rate, to the pickup location thereby allowing drivers to work more efficiently,
                                    boosting driver revenue, and providing consumers with affordable quality limousine
                                    service when they wanted it. Our telephones number is similar BellMe</p>

                                <h3>The Blog</h3><br/>
                                <p>Every qualified person who drives with BellMe is truly an independent
                                    contractor because of these most important legal factors. The first two are most
                                    important.
                                </p>
                                <ol>
                                    <li>You Set Your Own Rates - We may recommend a minimum rate to remain competitive
                                        but still be able to earn a living wage. Always remember you are competing in a
                                        global free-market. Your appearance, your personality, your driver skills, the
                                        vehicle you drive and how clean you keep it, and your rates make all the
                                        difference on how much business comes your way from us. You will always see the
                                        same rate the client is quoted. We are totally transparent, and we will never
                                        try to deceive you or the client when it comes to money. You only need to add
                                        tolls or waiting time. Auto toll calculations coming soon. It’s all about TRUST!
                                    </li>
                                    <li>Pick Up and Drop Off Locations - You will always see these locations when you
                                        get a new booking.
                                    </li>
                                    <li>You Finalize Each Booking – When the ride is over, finalize the total cost and
                                        get paid the same week. Auto bank deposits coming soon.
                                    </li>
                                    <li>ACCEPT or REJECT Any Booking - The only two reasons we would expect you to
                                        REJECT one of our bookings is that you’re busy taking care of your own client or
                                        doing a booking for another network. Unless you REJECT more bookings than
                                        ACCEPT, you may not be a good for us. Only join us if you have the excess
                                        capacity to ACCEPT most of our bookings or desire more quality bookings from us.
                                    </li>
                                    <li>You Set Your Own Hours – Choose to work mornings, afternoons, evenings. Do not
                                        work more hours than legally allowed. Take frequent rest breaks and don’t burn
                                        out. You should not have to work part time more than 5 or 6 days a week. A
                                        typical full day schedule for a “limo” driver is no more than 10-12 hours with
                                        breaks in between. Figure one booking takes a minimum of 1 hour or more, from
                                        the time you drive to the pickup and return home or drive to your next pickup if
                                        you are lucky not to drive a long distance or deadhead. 8 bookings in a day for
                                        a “limo” driver is a lot and can is tiring. You cannot keep this pace 7 days a
                                        week!!!
                                    </li>
                                    <li>Driver Unions - No one needs to belong to a union if you’re able to earn a
                                        living wage and work under fair working conditions that you control.
                                    </li>
                                    <li>Change Rates in Real-Time – We give you the simple tools to change your rates to
                                        be competitive and work more efficiently.
                                    </li>
                                </ol>
                                <h3>We Only Work With The Best</h3>
                                <p>Not every Taxi, Limo or Gig driver is great. BellMe wants to change that
                                    paradigm. My job is to attract the best drivers and keep them at their best. Gig and
                                    Taxi Drivers that deliver great customer services, like the average Limo Driver,
                                    will earn a living wage.
                                </p>
                                <p>Lyft and Uber did just a few good things to disrupt the taxi and livery industry,
                                    even though they did totally illegally, stepped on many toes, stole business from
                                    taxis and public transportation, and made transportation inconsistent and unsafe for
                                    consumers.</p>
                                <p>Lyft and Uber are successful because they made hailing a ride convenient, but the
                                    iPhone was instrumental to making that happen.
                                </p>
                                <p>Lyft and Uber’s disruptiveness did open the eyes of local regulators and exposed the
                                    archaic rules of over regulation of the taxi and liver industry. Much needed
                                    competition improved the taxi industry. Taxi companies and drivers became lazy over
                                    the decades because local governments failed to enforce their own regulations.
                                </p>
                                <p>TNCs used disruptive and aggressive tactics to win market share; they flooded the
                                    streets with unqualified drivers, brought wage deflation by selling rides below
                                    cost, they continue to harm the trillion-dollar global industry, and city traffic
                                    has increased exponentially by offering on-demand rides. Their number one selling
                                    point to city regulators was that there would be less traffic. I knew differently. I
                                    also knew there wasn’t enough room at most airports to handle their expected
                                    increased traffic. Still a problem today!
                                </p>
                                <p>Now both companies are back peddling to correct all their failures with their drivers
                                    and traffic congestion.</p>
                                <p>It’s my professional opinion they will both fail in the end with their current
                                    business model. They can’t earn a profit by charging below market rates forever and
                                    they will continually to lose drivers. Why do half of their drives leave within
                                    their first year and Why do they pay drivers sign up bonuses? Because working for
                                    them sucks. Slavery ended in 1865. You now have a better alternative network to work
                                    with.
                                </p>
                                <p>Why work for two companies that charge slave labor rates and eventually want to
                                    replace you with a robot car?
                                </p>
                                <p>The partial solution to reducing traffic congestion are: Free mass transportation
                                    systems, further deregulation of the taxi and limo industry equal to TNCs, and
                                    Shared Rides to and from areas where large numbers of people are going, locally and
                                    longer distances. It’s all about maximizing efficiencies and we’ll soon have this
                                    solution in place.
                                </p>
                                <p>BellMe has never had to back pedal to fix any social or regulatory wrongs.
                                    We’ve followed every regulation and never stepped on toes or buy market share to
                                    grow the company at our competitors’ expense. We believe in honest competition. Let
                                    the market decides who’s best.
                                    We have the best safety record in the industry. We have zero major accidents, zero
                                    injuries, zero assaults, zero battery or worse, zero lawsuits, and our data has
                                    never been breached. We only work with licensed, insured and vetted drivers with a
                                    clean DMV record. We do our best to screen and vet our drivers.
                                </p>
                                <p>Soon, all Gig drivers will have to obtain a FOID card (Firearm Ownership ID). We do
                                    not promote gun ownership but use the FOID card as the best background check in the
                                    industry. We’ll also use our client’s valued feedback to keep only the best drivers
                                    on the road with us. All other unqualified drivers can keep working with Lyft and
                                    Uber, etc.
                                </p>
                                <p>Gig drivers have no support the most part when they drive for Lyft and Uber. We have
                                    always been driver centric so they’re able to learn to be the best and earn a living
                                    wage. It’s expensive to own, operate and insure a vehicle. We only want the nicest
                                    and safest vehicles picking you up now matter if a short or long-distance ride. We
                                    do a better job of treating our drivers as small businesspeople. We help manage
                                    their business better than they can on their own. We are realistic what a driver can
                                    earn without burning out and what fair rates are required for them to make a small
                                    profit, just not pay their bills. This is their business, not a hobby.</p>
                                <p>We are not focused on growth but being the best at what we do and that is to get you
                                    to and from any location on-time, safely, and at free-market, competitive rates. We
                                    never Surge Price a client.
                                    I highly recommend getting to know me and my company. I’m passionate about the
                                    transportation industry. Go through all the content and resources I’ve compiled.
                                    BellMe is a unique community I’m proud to have created. I put 17 years of
                                    hard work and diligence into the company and I’m not done yet. We’re just scratching
                                    the surface on the ride-sharing economy and regulations still need rewriting.
                                    We only want the best people working with us, to be the best in every way and on
                                    every level!
                                </p>
                                <h3>Sign Up and Drive - Taxi, Black Car, Limo, Gig (where legal), Armored, Bus, Trolley,
                                    Antique</h3>
                                <p>My goal is to recruit 500,000 of the best drivers with the nicest luxury vehicles, in
                                    the largest and busiest cities around the world. We do not want to the biggest
                                    network, just the best value for clients and best support for drivers. Not everyone
                                    can join. You must qualify with a personal security background check and meet our
                                    vehicle requirements.</p>
                                <p>There is no cost to join. Accept or Reject Any Booking. Know the pickup and drop off
                                    location when you receive a new booking. Work locally from your neighborhood and
                                    where the business is.</p>
                                <ul>
                                    <li>Why do 50% of Lyft and Uber drivers quit less than 1 year of driving?
                                    </li>
                                    <li>When Lyft and Uber offer you signing bonuses, there’s a reason. The work and pay
                                        is lousySet your own rates, it’s a free-market. Enter your minimum rate, mileage
                                        rate, city rate, airport rate, and hourly rate.
                                    </li>
                                    <li>We automatically add a 20% tip. Earn it by providing a smooth ride with great
                                        customer service. You make the difference.
                                    </li>
                                    <li>The lowest rate you can enter for a mid-size, non-luxury makes and models, is a
                                        $1.60 per mile.
                                    </li>
                                    <li>The minimum rate for a short trip in a non-luxury mid-size vehicle is $5.</li>
                                    <li> Get paid within 3-5 business days.
                                    </li>
                                    <li> We take a 25% commission.
                                    </li>
                                    <li> We are here to help you succeed so you don’t burn out.
                                    </li>
                                    <li> If you know a great driver, have them join us.
                                    </li>
                                </ul>
                                <h3>Sign up as a Tour Guide
                                </h3><br/>
                                <ul>
                                    <li> What’s your specialty?</li>
                                    <ul>
                                        <li>History
                                        </li>
                                        <li>Art</li>
                                        <li>Architecture
                                        </li>
                                        <li>City Tour
                                        </li>
                                    </ul>
                                    <li>Enter your profile
                                    </li>
                                    <li>Enter your city or region, your hourly rate, your minimum number of hours</li>
                                    <li>Enter your language(s).
                                    </li>
                                    <li>We receive a 25% commission same as a driver.</li>
                                </ul>
                                <br/>
                                <h3>Insurance Requirements</h3><br/>
                                <p>You need to have the correct insurance. Here is a list of options by state. Insurance
                                    is not all the same. You can get more information here.</p>
                                <h3>Maximize Your Profits and Keep Expenses Low</h3><br/>
                                <p>Learn the transportation industry if you’re a Gig Driver. Drive like a limo driver
                                    and not your average, years past, taxi driver. Learn customer service. Drive like a
                                    pro. If people like you, they give you money. Maintain your vehicle, it’s your money
                                    maker. Owning the right vehicle is most important. Read Consumer Reports for the
                                    most reliable vehicles. Here is our list of the vehicles that will make you money.
                                    Click here to get more information.
                                </p>
                                <h3>Subscribe to our eNewsletter (future)</h3><br/>
                                <p>Stay on top of all the latest news and subscribe by email. I’ll post as needed and
                                    I’ll podcast soon. I’ll never spam you and you can unsubscribe at any time.
                                    You can review all you need to know before joining my global network. It’s a
                                    privilege to be a one of my affiliate partner’s:
                                </p>
                                <ul>
                                    <li>Guide to Drive with BellMe</li>
                                    <li>Training Guide</li>
                                    <li>Getting Started</li>
                                </ul>
                                <h3>Subscribe to My Podcast (future)</h3><br/>
                                <p>I’ll do my best to publish at least one episode per month. A lot of work will go into
                                    each podcast, so I’ll make it as interesting as possible.
                                    If you’re interested in subscribing to our podcast, it will be available on iTunes
                                    or wherever you get you view.
                                </p>
                                <h3>Subscribe to My YouTube Channel (future)</h3><br/>
                                <p>If you follow YouTube, we’ll be producing tutorials and showing affiliate videos to
                                    assist you in being the best drivers on the road. It’s all about nicer, newer,
                                    larger vehicles, and customer service.</p>
                            </div>
                            <br/>

                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
